@import "styles/variables";

.staticPage {
  .title {
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 20px;
    @media (min-width: 750px) {
      padding: 0 75px;
    }
    @media (min-width: 900px) {
      font-size: 72px;
      line-height: 84px;
      padding: 0 150px;
    }
  }
  .content {
    margin: 0 auto;
  }
  img {
    max-width: 100%;
    height: auto !important;
  }
  iframe {
    max-width: 100%;
  }

  h2 {
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
  }

  h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
  }
  .categories{
    .category{
      margin-bottom: $general-gutter-2;
    }
  }

  :global(.box) {
    padding: 12px;
    background: #ffffff;
  }

  :global(.hidden) {
    display: none;
  }

  :global(.openable-content) {
    position: relative;
    width: 100%;
    display: block;
    cursor: pointer;
    & > * {
      background-color: $grey-bg-color;
      position: relative;
      display: inline-block;
      padding-right: 10px;
    }
    &:before {
      content: " ";
      width: 100%;
      height: 1px;
      border-bottom: 1px solid black;
      position: absolute;
      top: 50%;
      margin-top: -2px;
      left: 0;
    }
    &:after {
      content: "";
      background-color: $grey-bg-color;
      background-image: url("https://instance11.admin.grandus.sk/files/attachment/2020-11/253/d98ec1/open.svg");
      background-position: center center;
      border-radius: 50%;
      margin-top: -16px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 28px;
      height: 28px;
    }
    &:global(.open) {
      &:after {
        background-image: url("https://instance11.admin.grandus.sk/files/attachment/2020-11/250/66b4cb/close.svg");
      }
    }
  }
  .attachments {
    h3 {
      padding-right: 52px;
    }
    .attachmentWrapper {
      padding: 15px 5px;
      display: inline-block;
      .attachment {
        position: relative;
        display: block;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        width: 186px;
        height: 186px;
        .content {
          color: black;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 20px;
          .label {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 20px;
          }
          .file {
            font-size: 400;
            font-size: 14px;
            margin-bottom: 20px;
          }
          svg {
            width: 24px;
            height: 16px;
          }
        }
        .hoverContent {
          opacity: 0;
          background-color: #43525a;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: opacity 0.3s;
          color: white;
          text-align: center;
          padding: 20px;
          svg {
            margin-bottom: 20px;
            width: 48px;
            height: 32px;
          }
          .detail {
            color: #fbd136;
          }
        }
        &:hover {
          box-shadow: none;
          text-decoration: none;
          .hoverContent {
            opacity: 1;
          }
        }
      }
    }
  }
}

.arrow {
  position: absolute;
  top: -40px;
  right: 0;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  &.left {
    right: 26px;
  }
  &:hover {
    color: #fbd136;
  }
}
