@import "styles/variables";

.product-hero-image {
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  height: 280px;
  padding: 30px 0;
  position: relative;

  @media screen and (min-width: $lg) {
    height: 452px;
    padding: 58px 0;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent linear-gradient(32deg, #000000 0%, #00000000 100%) 0 0 no-repeat padding-box;
  }

  &__content-container {
    width: 100%;
  }

  &__title {
    color: white;
    font-weight: bold;
    line-height: 1.1;
    font-size: 38px;
    margin: 0;

    @media screen and (min-width: $lg) {
      font-size: 65px;
    }
  }
}