@import "styles/variables";

.contactContainer {
  width: 100%;

  h3 {
    font-weight: bold;
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 50px;
    margin-top: 15px;
  }
}

.contactWrapper {
  max-width: 1228px;
  margin: 0 auto;
}

.contactBox {
  background-color: $grey-bg-color;

  label {
    font-size: 18px;
    display: block;
    margin-bottom: 15px;

    @media screen and (min-width: $lg) {
      font-size: 24px;
    }
  }

  padding: 20px 15px;

  @media screen and (min-width: $lg) {
    padding: 55px 55px 80px;
  }

  input[type="text"], textarea {
    font-size: 16px;
    padding: 10px 15px;

    @media screen and (min-width: $lg) {
      font-size: 18px;
      padding: 10px 15px;
    }
  }
}

.textCenter {
  text-align: center;
}

.contactSelectInput {
  font-size: 16px;

  > div {
    border-color: transparent !important;
    border-radius: 24px !important;
  }

  @media screen and (min-width: $lg) {
    font-size: 18px;
  }
}