@import "styles/variables";

.headLine {
  margin-top: -65px;
  background-color: #ffffff;
  margin-bottom: 20px;
  .menuLine {
    height: 65px;
    background-color: $grey-bg-color;
  }
  .gutterLine {
    background-color: #ffffff;
    height: 100px;
  }
  &.profile {
    .menuLine {
      background-color: #fbd136;
    }
    .gutterLine {
      background-color: #3ca3db;
    }
  }
  &.page {
    .menuLine {
      background-color: white;
    }
    .gutterLine {
      background-color: $grey-bg-color;
    }
  }
}
